import {useQuery} from "react-query";
import {
    ACHIEVEMENTS,ADDRESSES,AWARDS,deleteElem,
    ENROLLMENTS,
    get,HOME_ADDRESS_HISTORY,MESSAGES,ORGANIZATIONS,post,put,
    SENSORS,
    SESSIONS,
    SMARTPHONES,
    USERS,WORK_ADDRESS_HISTORY
} from "./Client";
import {db} from "../db";
import {useLiveQuery} from "dexie-react-hooks";

export function useGetUsers() {
    return useLiveQuery(() => db.users.toArray()) || []
}

export function useGetUsersFetchStatus() {
    return useLiveQuery(() => db.fetchStatus.get("users"))
}

export function createAdmin(newAdmin){
    let remote = post(`${USERS}/admin`, {body: newAdmin})
    return remote.then(({data}) => db.users.put(data))
}

export function editUser(uid, field, value) {
    let remote = put(`${USERS}/${uid}`, {body: {[field]: value}})
    return remote.then(() => db.users.where('uid').equals(uid).modify(u => u[field] = value))
}

export function deleteUser(uid) {
    let remote = deleteElem(`${USERS}/${uid}`)
    return remote.then(() => db.users.where('uid').equals(uid).delete());
}

export function synchronizeUsers() {
    db.fetchStatus.get("users").then(status => {
        db.users.count().then(un => {
            if(un !== 0 && status?.status === "ok" && status?.date && (new Date() - status?.date) < 20*60*1000) return;
            if (un === 0) synchronizeUsersEmptyDB()
            else synchronizeUsersFullDB()
        })
    })
}

function synchronizeUsersEmptyDB() {
    db.fetchStatus.put({key: "users", status: "loading"})
    get(USERS).then((allUsers) => {
        db.users.clear()
        db.users.bulkPut(allUsers).then(() => {
            db.fetchStatus.put({key: "users", status: "loadingOtherData"})
            updateUsersAddressesEnrollments(allUsers)
        })
    }).catch(() => db.fetchStatus.put({key: "users", status: "error"}))
}

function synchronizeUsersFullDB() {
    db.fetchStatus.put({key: "users", status: "reloading"})
    get(USERS).then((allUsers) => {
        updateUsersAddressesEnrollments(allUsers)
    }).catch(() => db.fetchStatus.put({key: "users", status: "error"}))
}

function updateUsersAddressesEnrollments(allUsers) {
    let gettingHomes = get(`${ADDRESSES}/${HOME_ADDRESS_HISTORY}`).then((data) => {
        data.forEach(ha => {
            let user = allUsers.find(u => u.uid === ha.uid)
            if(user) {
                if(user.homeAddresses) user.homeAddresses.push(ha)
                else user.homeAddresses = [ha]
            }
        })
    })
    let gettingWorks = get(`${ADDRESSES}/${WORK_ADDRESS_HISTORY}`).then((data) => {
        data.forEach(wa => {
            let user = allUsers.find(u => u.uid === wa.uid)
            if(user) {
                if(user.workAddresses) user.workAddresses.push(wa)
                else user.workAddresses = [wa]
            }
        })
    })
    let gettingEnrollments = get(`${ORGANIZATIONS}/${ENROLLMENTS}`).then((data) => {
        data.forEach(en => {
            let user = allUsers.find(u => u.uid === en.uid)
            if(user) {
                if(user.enrollments) user.enrollments.push(en)
                else user.enrollments = [en]
            }
        })
    })
    Promise.all([gettingHomes, gettingWorks, gettingEnrollments]).finally(() => {
        db.users.clear()
        db.users.bulkPut(allUsers)
        db.fetchStatus.put({key: "users", status: "ok", date: new Date()})
    })
}

export function useGetOrganizationUsers(oid, active=false) {
    const {
        status,
        data,
        error
    } = useQuery([USERS, {oid: oid}], () => get(USERS, {params: {oid: oid, active}}), {cacheTime: 10 * 60 * 1000});
    return {status, users: data || [], error};
}

export function useGetProfileUser(uid) {
    const {status, data, error} = useQuery([USERS, {user: uid}], () => get(USERS, {elem: uid}));
    return {status, user: data || {}, error};
}

export function useGetUserDashboard(uid) {
    const {
        status,
        data,
        error
    } = useQuery([`${USERS}-dashboard`, {user: uid}], () => get(`${USERS}/${uid}/dashboard`), {
        staleTime: 60 * 1000,
        refetchOnMount: false
    });
    return {status, dashboard: data || {}, error};
}

export function useGetProfileUserSensors(uid) {
    const {
        status,
        data,
        error
    } = useQuery([USERS, {user: uid}, SENSORS], () => get(USERS, {elem: uid + "/" + SENSORS}));
    return {status, sensors: data || [], error};
}

export function useGetProfileUserSmartphones(uid) {
    let {
        status,
        data,
        error
    } = useQuery([USERS, {user: uid}, SMARTPHONES], () => get(USERS, {elem: uid + "/" + SMARTPHONES}));
    return {status, smartphones: data || [], error};
}

export function useGetProfileUserEnrollments(uid, enabled = true) {
    const {
        status,
        data,
        error
    } = useQuery([USERS, {user: uid}, ENROLLMENTS], () => get(USERS, {elem: uid + "/" + ENROLLMENTS}), {enabled});
    return {status, enrollments: data || [], error};
}

export function useGetProfileUserAchievements(uid) {
    const {
        status,
        data,
        error
    } = useQuery([USERS, {user: uid}, ACHIEVEMENTS], () => get(USERS, {elem: uid + "/" + ACHIEVEMENTS, params: {active: false}}));
    return {status, achievements: data || [], error};
}

export function useGetProfileUserAwards(uid) {
    const {
        status,
        data,
        error
    } = useQuery([USERS, {user: uid}, AWARDS], () => get(USERS, {elem: `${uid}/${AWARDS}`}));
    return {status, awards: data || [], error};
}

export function useGetProfileUserMessages(uid) {
    const {
        status,
        data,
        error
    } = useQuery([USERS, {user: uid}, MESSAGES], () => get(USERS, {elem: `${uid}/${MESSAGES}`}));
    return {status, messages: data || [], error};
}

export function useGetUserSessions(uid) {
    const {
        status,
        data,
        error
    } = useQuery([SESSIONS, USERS, {uid}], () => get(USERS, {elem: `${uid}/${SESSIONS}-overview`}));
    return {status, sessions: data, error};
}

export function useGetUserIntervalSessions(uid, start, end) {
    const {status, data, error} = useQuery([SESSIONS, USERS, {uid}, {
        start,
        end
    }], () => get(USERS, {elem: `${uid}/${SESSIONS}-overview`, params: {start, end}}), {enabled: !!start && !!end});
    return {status, sessions: data, error};
}

export function useGetUserVerifiedSessions(uid) {
    const {status, data, error} = useQuery([SESSIONS, USERS, {uid}], 
        () => get(USERS, {elem: `${uid}/${SESSIONS}-verified`}));
    return {status, verifiedSessions: data, error};
}

export function useGetAddressHistory(uid, url) {
    const {
        status,
        data,
        error
    } = useQuery([url, {uid}], () => get(USERS, {elem: uid + "/" + url}), {
        staleTime: 30 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });
    return {status, addresses: data, error};
}