import React, { useContext, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import userImage from "../../images/top_bar-profilo-over.svg";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import { AWARDS, MESSAGES, post, SESSIONS, USERS, WORK_ADDRESS } from "../../services/Client";
import { getErrorMessage, put } from "../../services/Client";
import { useGetCities, useGetOrganizations } from "../../services/ContentManager";
import LockIcon from '@mui/icons-material/Lock';
import RedeemIcon from '@mui/icons-material/Redeem';
import Button from "@mui/material/Button";
import { useQueryClient } from "react-query";
import { Avatar } from "@mui/material";
import ChangeCredientalsModal from "../../components/modals/ChangeCredentialsModal";
import FileInput from "../../components/forms/FileInput";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import { UserContext } from "../App";
import FilterAndSearchBar from "../../components/layout/FilterAndSearchBar";
import {
    UserAchievements, UserAwards,
    UserEnrollments, UserMessages,
    UserSensors,
    UserSessions,
    UserSmartphones
} from "./ComponentsProfileContainer";
import { AbilityContext } from "../../services/Can";
import CustomAwardModal from "../../components/modals/CustomAwardModal";
import {
    useGetAddressHistory,
    useGetProfileUser,
    useGetProfileUserEnrollments,
    useGetUserDashboard,
    useGetUserIntervalSessions,
    useGetUsers,
    useGetUserVerifiedSessions
} from "../../services/UserDataManager";
import SendMessageModal from "../../components/modals/SendMessageModal";
import CreateSessionModal, { repeatType } from "../../components/modals/CreateSessionModal";
import SendIcon from '@mui/icons-material/Send';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import { resources, roles } from "../../services/ability";
import { useTranslation } from "react-i18next";
import TextInput from "../../components/forms/TextInput";
import { firebaseAuth } from "../../firebase";
import { formatAddress } from "../../services/helper";
import GenderSelect from "../../components/GenderSelect";
import { getGenderCodeValue } from "../../constants/genderCode";
import InfoModal from "../../components/modals/InfoModal";
import { Typography } from "../../../node_modules/@mui/material/index";

const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2)
    },
    container: {
        marginBottom: theme.spacing(2)
    },
    box: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        borderBottom: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        flexGrow: 1,
    },
    textField: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        border: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        flexGrow: 1
    },
    userImage: {
        height: "8rem",
        width: "8rem",
        color: theme.palette.primary.main
    },
    editUserImage: {
        color: "#fff",
        backgroundColor: theme.palette.primary.dark,
    },
    filterBar: {
        padding: theme.spacing(4),
        paddingBottom: 0
    },
}));

export default function ProfilePage() {

    const { t } = useTranslation();
    let classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    let queryClient = useQueryClient();
    const { uid, filter } = useParams();
    let users = useGetUsers();
    let { user = {} } = useGetProfileUser(uid);
    let { dashboard = {} } = useGetUserDashboard(uid);
    let { addresses: workAddresses } = useGetAddressHistory(uid, WORK_ADDRESS)
    let cities = useGetCities();
    let { organizations = [] } = useGetOrganizations();
    const { enrollments = [] } = useGetProfileUserEnrollments(uid)
    const loggedUser = useContext(UserContext);
    const isUserLogged = uid === loggedUser.uid;
    let [isEditable, setIsEditable] = useState(false);
    let [isEditingCredentials, setIsEditingCredentials] = useState(false);
    let [editUser, setEditUser] = useState({});
    let [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
    let [isCreatingSession, setIsCreatingSession] = useState(false)
    let history = useHistory();
    const ability = useContext(AbilityContext);
    const canReadPrivateData = ability.can("read", resources.PRIVATEUSERDATA)
    const [awardAchievement, setAwardAchievement] = useState(null);
    const [awardPosition, setAwardPosition] = useState(null);
    const [awardRanking, setAwardRanking] = useState(null);

    const form = (withSerialNumber, withIban) => {
        let form = [
            {
                name: "firstName",
                label: t('firstName')
            },
            {
                name: "lastName",
                label: t('lastName')
            },
            {
                name: "username",
                label: t('username'),
                error: (newValue) => {
                    if (users.some(u => u.username === newValue) && newValue !== user.username)
                        return t('usernameAlreadyExist')
                }
            },
            {
                name: "email",
                label: t('email'),
                notEditable: true
            }]

        if (withSerialNumber) form.push({
            name: "serialNumber",
            label: t('serialNumber'),
        });
        form.push(...[
            {
                name: "phoneNumber",
                label: t('phoneNumber')
            },
            {
                name: "birthDate",
                label: t('birthday'),
                type: "date"
            },
            {
                name: "fiscalCode",
                label: t('fiscalCode')
            },
            {
                name: "genderCode",
                label: t('gender'),
                type: "select",
                isGender: true
            },
        ])
        if (withIban) form.push({
            name: "iban",
            label: t('iban')
        })
        return form;
    }

    function getFilters(uid, ability) {

        let filters = [];
        let id = 0;

        if (loggedUser.userType !== roles.PINBIKER) {
            filters.push({
                id,
                filter: "sensors",
                name: t('sensors'),
                component: <UserSensors uid={uid} />
            });
            id++;
        }
        if (ability.can('read', USERS + SESSIONS) || loggedUser.userType === roles.PINBIKER) {
            filters.push({
                id,
                filter: "sessions",
                name: t('sessions'),
                component: <UserSessions uid={uid} />
            })
            id++;
        }
        filters.push({
            id,
            filter: "enrollments",
            name: t('initiatives'),
            component: <UserEnrollments uid={uid} />
        });
        id++;
        filters.push({
            id,
            filter: "achievements",
            name: t('achievements'),
            component: <UserAchievements uid={uid} />
        });
        if (loggedUser.userType !== roles.PINBIKER) {
            id++;
            filters.push({
                id,
                filter: "smartphones",
                name: t('smartphones'),
                component: <UserSmartphones uid={uid} />
            });
            id++;
            filters.push({
                id,
                filter: "messages",
                name: t('messages'),
                component: <UserMessages uid={uid} />
            });
        }
        id++;
        filters.push({
            id,
            filter: "awards",
            name: t('awards'),
            component: <UserAwards uid={uid} />
        });

        return filters;
    }

    const filters = getFilters(uid, ability)

    const onFilter = (filterId) => {
        history.push(filters.find(f => f.id === filterId).filter)
    }

    const onTextChange = (value, name) => {
        let newEditUser = { ...editUser };
        newEditUser[name] = value;
        setEditUser(newEditUser);
    }

    const saveEdit = () => {
        let editedUser = {
            ...editUser,
            birthDate: Date.parse(editUser.birthDate),
            gender: getGenderCodeValue(editUser.genderCode)
        }
        let editedData = Object.fromEntries(Object.entries(editedUser).filter(([key, value]) => value !== user[key] && typeof value !== "object"))
        if (users.some(u => u.username === editUser.username) && editUser.username !== user.username) {
            return;
        }
        enqueueSnackbar(t('saving...'), { variant: "info" });
        put(USERS, { body: editedData, elem: user.uid })
            .then(() => {
                enqueueSnackbar(t('saved'), { variant: "success" });
                setIsEditable(false);
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries([USERS, { user: user.uid }]));
    }

    const edit = () => {
        setEditUser({ ...user });
        if (user.birthDate) setEditUser({ ...user, "birthDate": dayjs(new Date(user.birthDate)).format("YYYY-MM-DD") });
        setIsEditable(true);
    }

    const assignAward = (values) => {
        values.uid = uid
        enqueueSnackbar(t('saving...'), { variant: "info" });
        post(`${AWARDS}/customs`, { body: values, params: { assignTo: uid } })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries("AwardsCustom"));
    }

    async function createSession(values) {
        values.uid = uid
        enqueueSnackbar(t('saving...'), { variant: "info" });

        if (values.repeatType === repeatType.DAILY) {

            let repetitions = values.repetitions
            delete values.repeatType
            delete values.repetitions
            for (let i = 0; i <= repetitions; i++) {
                await post(`${SESSIONS}/generate`, { body: values })
                    .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
                    .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
                    .finally(() => queryClient.invalidateQueries([SESSIONS, USERS, { uid }]));
                values.startTime += 86400000
                values.endTime += 86400000
            }

        } else if (values.repeatType === repeatType.ROUND_TRIP) {

            delete values.repeatType
            let startTimeGoBack = values.startTimeGoBack
            let endTimeGoBack = values.endTimeGoBack
            delete values.startTimeGoBack
            delete values.endTimeGoBack
            await post(`${SESSIONS}/generate`, { body: values })
                .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
                .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
                .finally(() => queryClient.invalidateQueries([SESSIONS, USERS, { uid }]));
            values.startTime = startTimeGoBack
            values.endTime = endTimeGoBack
            post(`${SESSIONS}/generate`, { body: values })
                .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
                .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
                .finally(() => queryClient.invalidateQueries([SESSIONS, USERS, { uid }]));

        } else {

            delete values.repeatType
            post(`${SESSIONS}/generate`, { body: values })
                .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
                .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
                .finally(() => queryClient.invalidateQueries([SESSIONS, USERS, { uid }]));

        }
    }

    let [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);
    let [notificationTitle, setNotificationTitle] = useState("");
    let [notificationBody, setNotificationBody] = useState("");

    const { verifiedSessions = [], status } = useGetUserVerifiedSessions(uid);

    const openNotificationModal = () => {
        setNotificationTitle(null);
        setNotificationBody(null);
        setIsSendNotificationModalOpen(true);
    }

    const openNotificationModalForValidated = () => {
        const sessionIds = verifiedSessions.map(s => dayjs(new Date(s.endTime)).format("DD/MM/YYYY HH:mm") + " ");
        setNotificationTitle("Sessioni da revisionare il " + dayjs(new Date()).format("DD/MM/YYYY HH:mm"));
        setNotificationBody("Gentile utente,\r\n\r\n" +
            "Ti informiamo che abbiamo completato la revisione delle tue sessioni che non risultavano certificate.\r\n" +
            "Puoi verificare le modifiche effettuate consultando in app l'elenco delle tue sessioni.\r\n\r\n" +
            "Ecco le sessioni revisionate:\r\n" +
            `${sessionIds.join(",\r\n")}\r\n\r\n` +
            "Per maggiori dettagli o se necessiti di assistenza, non esitare a contattare il nostro supporto.\r\n" +
            "Grazie per la tua pazienza e comprensione.\r\n\r\n" +
            "Cordiali saluti,\r\n" +
            "Il team di supporto");
        setIsSendNotificationModalOpen(true);
    }

    const sendMessage = (values) => {
        enqueueSnackbar(t('sending...'), { variant: "info" });
        values.sendTo = [uid]

        post(`${MESSAGES}/send`, { body: values })
            .then(() => {
                setIsSendNotificationModalOpen(false)
                enqueueSnackbar(t('sent'), { variant: "success" })
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
    }

    const sendResetPasswordMail = () => {
        firebaseAuth.sendPasswordResetEmail(user.email)
            .then(() => {
                enqueueSnackbar(t('sent'), { variant: "success" });
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
    }

    if (isEditable) return (
        <Paper className={classes.page}>
            <Grid container className={classes.container}>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Grid>
                        <IconButton onClick={saveEdit} size="large">
                            <CheckIcon />
                        </IconButton>
                        <IconButton onClick={() => setIsEditable(false)} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "13rem" }}>
                        <FileInput folder="users/avatars" prefix={user.uid}
                            onRequestSave={(e) => setEditUser({ ...editUser, "avatarUrl": e })} />
                    </div>
                </Grid>
                <Grid container className={classes.container} alignItems="flex-start">
                    {
                        form(!!user.serialNumber, !!user.iban).map(f => (!f.notEditable) &&
                            <Grid xs={12} md={6} key={f.name} sx={{ padding: "12px" }}>
                                {
                                    f.isGender ?
                                        <GenderSelect
                                            value={editUser[f.name]}
                                            onTextChange={(value) => onTextChange(value, f.name)}
                                            InputLabelProps={{ shrink: true }}
                                            color="dark"
                                            error={f.error && f.error(editUser[f.name])}
                                        />
                                        :
                                        <TextInput value={editUser[f.name]}
                                            onTextChange={(value) => onTextChange(value, f.name)} type={f.type}
                                            label={f.label}
                                            color="dark" InputLabelProps={{ shrink: true }}
                                            error={f.error && f.error(editUser[f.name])}
                                        />
                                }
                            </Grid>)

                    }
                </Grid>
            </Grid>
        </Paper>
    )

    return (
        <Paper className={classes.page}>
            <Grid container className={classes.container}>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-start" }}>
                    {(isUserLogged && loggedUser.userType !== roles.PINBIKER) &&
                        <Button
                            color="primary"
                            startIcon={<LockIcon />}
                            onClick={() => setIsEditingCredentials(true)}
                        >
                            {t('changePassword')}
                        </Button>
                    }
                    {
                        //pulsante visibile all'admin per inviare la mail di reset password
                        (!isUserLogged && loggedUser.userType === roles.ADMIN) &&
                        <Button
                            color="primary"
                            startIcon={<LockIcon />}
                            onClick={() => sendResetPasswordMail()}
                        >
                            {t('sendEmailResetPassword')}
                        </Button>
                    }
                </Grid>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    {(loggedUser.userType !== roles.PINBIKER) &&
                        <IconButton onClick={edit} size="large">
                            <EditIcon />
                        </IconButton>
                    }
                </Grid>

                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Avatar src={user.avatarUrl || userImage} alt="userImage" className={classes.userImage}
                        sx={{ width: "8rem", height: "8rem" }} />
                </Grid>

                {(loggedUser.userType === roles.PINBIKER) &&
                    <Grid item xs={12} sx={{ my: 1, mb: 1 }}>
                        <Typography align={'center'} variant={'h6'}>{t('suggestUsingMobileApp')}</Typography>
                    </Grid>
                }

            </Grid>

            <Grid container alignItems={"flex-end"} className={classes.container}>
                {
                    form(!!user.serialNumber, !!user.iban).map(f => (!isEditable || !f.notEditable) &&
                        <Grid item xs={12} md={6} style={{ display: "flex" }} key={f.name}>

                            <Box xs={6} className={classes.box}>
                                <div style={{ fontWeight: "normal" }}>{f.label}</div>
                                <div style={{ marginLeft: "1rem" }}>
                                    {f.type === "date" ?
                                        user[f.name] ? dayjs(new Date(user[f.name])).format("DD/MM/YYYY") : "-"
                                        : f.isGender ? t(getGenderCodeValue(user[f.name])) : user[f.name] || "-"}
                                </div>
                            </Box>

                        </Grid>)
                }
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                    <Box xs={6} className={classes.box}>
                        <div style={{ fontWeight: "normal" }}>{t('nationalKm')}</div>
                        <div
                            style={{ marginLeft: "1rem" }}>{(dashboard.distance ? dashboard.distance.toFixed(2) : 0) + " km"}</div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                    <Box xs={6} className={classes.box}>
                        <div style={{ fontWeight: "normal" }}>{t('nationalPoints')}</div>
                        <div style={{ marginLeft: "1rem" }}>{dashboard.points || 0}</div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                    <Box xs={6} className={classes.box}>
                        <div style={{ fontWeight: "normal" }}>{t('homeAddress')}</div>
                        <div style={{ marginLeft: "1rem" }}>
                            {user["homeAddress"] ?
                                formatAddress({
                                    address: user["homeAddress"],
                                    number: user["homeNumber"],
                                    cityName: cities.length !== 0 && cities.find(c => c.istatId === user["homeCity"]) ? cities.find(c => c.istatId === user["homeCity"]).city : ""
                                }) : "-"
                            }
                        </div>
                    </Box>
                </Grid>
                {(user?.workAddresses || workAddresses) && organizations &&
                    (user?.workAddresses || workAddresses).filter(a => !!organizations.find(o => o.id === a.organization)).map(wa => <Grid item
                        xs={12}
                        md={6}
                        style={{ display: "flex" }}>
                        <Box xs={6} className={classes.box}>
                            <div style={{
                                fontWeight: "normal",
                                paddingBottom: "0.1rem"
                            }}>{t('workAddress')} {organizations.length !== 0 && organizations.find(o => o.id === wa.organization) && organizations.find(o => o.id === wa.organization).title}</div>
                            <div style={{ marginLeft: "1rem" }}>
                                {formatAddress(wa)}
                            </div>
                        </Box>
                    </Grid>)
                }
            </Grid>
            <Grid container alignItems={"flex-end"} className={classes.container}>
                {
                    enrollments.map(e => <>
                        <Grid item xs={12} md={4} style={{ display: "flex" }}>
                            <Box xs={6} className={classes.box}>
                                <div style={{
                                    fontWeight: "normal",
                                    paddingBottom: "0.1rem"
                                }}>{t('km')} - {organizations.length !== 0 && (organizations.find(o => o.id === e.organization) || {}).title}</div>
                                <div style={{ marginLeft: "1rem" }}>{(e.urbanKm || 0).toFixed(2)}</div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ display: "flex" }}>
                            <Box xs={6} className={classes.box}>
                                <div style={{
                                    fontWeight: "normal",
                                    paddingBottom: "0.1rem"
                                }}>{t('points')} - {organizations.length !== 0 && (organizations.find(o => o.id === e.organization) || {}).title}</div>
                                <div style={{ marginLeft: "1rem" }}>{(e.points || 0).toFixed(0)}</div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ display: "flex" }}>
                            <Box xs={6} className={classes.box}>
                                <div style={{
                                    fontWeight: "normal",
                                    paddingBottom: "0.1rem"
                                }}>{t('euro')} - {organizations.length !== 0 && (organizations.find(o => o.id === e.organization) || {}).title}</div>
                                <div style={{ marginLeft: "1rem" }}>{(e.euro || 0).toFixed(2)} {t('currency')}</div>
                            </Box>
                        </Grid>
                    </>)
                }
            </Grid>

            <Grid container>
                {canReadPrivateData && <Grid>
                    <Button
                        color="primary"
                        startIcon={<RedeemIcon />}
                        onClick={() => setIsAssignModalOpen(true)}
                    >
                        {t('assignAward')}
                    </Button>
                </Grid>}

                <Grid>
                    {(loggedUser.userType !== roles.PINBIKER) &&
                        <Button
                            color="primary"
                            startIcon={<SendIcon />}
                            onClick={() => openNotificationModal()}
                        >
                            {t('sendNotification')}
                        </Button>
                    }
                </Grid>

                {canReadPrivateData && <Grid>
                    {(loggedUser.userType !== roles.PINBIKER) &&
                        <Button
                            color="primary"
                            disabled={verifiedSessions.length === 0}
                            startIcon={<SendIcon />}
                            onClick={() => openNotificationModalForValidated()}
                        >
                            {t('sendNotificationValidated')}
                        </Button>
                    }
                </Grid>}

                {canReadPrivateData && <Grid>
                    <Button
                        color="primary"
                        startIcon={<DirectionsBikeIcon />}
                        onClick={() => setIsCreatingSession(true)}
                    >
                        {t('createSession')}
                    </Button>
                </Grid>}
            </Grid>


            <Grid container className={classes.filterBar}>
                <FilterAndSearchBar
                    filters={filters}
                    onFilter={onFilter}
                    selected={filters.find(f => f.filter === filter).id}
                />
            </Grid>
            <div className={classes.page}>
                {filters.find(f => f.filter === filter).component}
            </div>



            <ChangeCredientalsModal open={isEditingCredentials} onClose={() => setIsEditingCredentials(false)} />
            <CustomAwardModal
                open={isAssignModalOpen}
                onClose={() => setIsAssignModalOpen(false)}
                onSubmit={assignAward}
                organizations={organizations}
                singleWin />
            <SendMessageModal
                open={!!isSendNotificationModalOpen}
                onClose={() => setIsSendNotificationModalOpen(false)}
                onSave={sendMessage}
                title={notificationTitle}
                body={notificationBody} />

            <CreateSessionModal open={!!isCreatingSession} onClose={() => setIsCreatingSession(false)}
                onSubmit={createSession} />

            <InfoModal open={!!awardAchievement} onClose={() => setAwardAchievement(null)} data={awardAchievement}
                infos={[
                    {
                        field: 'achievementName',
                        label: t('achievement')
                    }
                ]} />

            <InfoModal open={!!awardPosition} onClose={() => setAwardPosition(null)} data={awardPosition}
                infos={[
                    /* {
                        field: 'latitude',
                        label: t('latitude')
                    }, {
                        field: 'longitude',
                        label: t('longitude')
                    }, */ {
                        field: 'address',
                        label: t('address')
                    }, {
                        field: 'number',
                        label: t('houseNumber')
                    }, {
                        field: 'cityName',
                        label: t('city')
                    }, {
                        field: 'radius',
                        label: t('toleranceRadius')
                    }, {
                        isDate: true,
                        field: 'startDate',
                        label: t('startDate')
                    }, {
                        isDate: true,
                        field: 'endDate',
                        label: t('endDate')
                    }
                ]} />

            <InfoModal open={!!awardRanking} onClose={() => setAwardRanking(null)} data={awardRanking}
                infos={[
                    {
                        field: 'rankingName',
                        label: t('rankingName')
                    }, {
                        field: 'position',
                        label: t('position')
                    }, {
                        field: 'range',
                        label: t('range')
                    }
                ]} />

        </Paper>
    );
}
